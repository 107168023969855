import React, { FC, useEffect, useMemo, useRef } from 'react'
import { getAllTowers, getConfig, getLinePaths } from '../redux/MapCRUD';
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { VOLTAGE_LEVEL } from '../redux/const';
import LoadingOverlay from 'react-loading-overlay-ts';

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 125px)'
};
const esMayorRiesgo = (riesgo1, riesgo2) => {
    const risk_levels = ['NO_REVISION', 'NORMAL', 'LOW_RISK', 'MEDIUM_RISK', 'HIGH_RISK'];
    return risk_levels.indexOf(riesgo1) > risk_levels.indexOf(riesgo2);
}

const MapPage: FC<any> = ({ filter, handleClickTower, lineData }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB75vOArqE952iTf4YVg6TrFGZL-_WU_Wg"
    });
    const [centerMap, setCenterMap] = React.useState<any>(null);
    const [zoomMap, setZoomMap] = React.useState<any>(8);
    const [loading, setLoading] = React.useState<any>('');

    const [listaTorresCompleta, setListaTorresCompleta] = React.useState<any>([]);
    const [listaTorres, setListaTorres] = React.useState<any>([]);
    const [torreHover, setTorreHover] = React.useState<any>(null);
    const [torreSeleccionada, setTorreSeleccionada] = React.useState<any>(null);
    const [listaTramos, setListaTramos] = React.useState<any>([]);
    const [listaLineasDeshabilitadas, setListaLineasDeshbilitadas] = React.useState<any>([]);

    const { t } = useTranslation();
    const mapRef = useRef<any>(null);

    //carga de config de mapa
    useEffect(() => {
        getConfig().then((response: any) => {
            setCenterMap({ lat: parseFloat(response.data.latitude), lng: parseFloat(response.data.longitude) });
            setZoomMap(response.data.zoom);
        })
    }, [])

    //carga de torres
    useEffect(() => {
        setLoading('Cargando estructuras');
        getAllTowers().then((response: any) => {
            setListaTorres(response.data);
            setListaTorresCompleta(response.data);
            setLoading('');
        })
    }, [])

    useMemo(() => {
        if (filter) {
            let voltageLevel = filter.voltageLevel;
            let revState = filter.revState;
            let issueCount = filter.issueCount;
            let linesFilter = filter.linesFilter;
            const linesIds = filter.lines;

            let filtradas = [];
            if (listaTorresCompleta) {
                if(voltageLevel) {
                    //se dibujan los tramos
                    const voltagesList = VOLTAGE_LEVEL.filter((vl, index) => voltageLevel[index]);
                    getLinePaths(linesIds, voltagesList)
                    .then((res: any) => {
                        setListaTramos(res.data);
                    });

                    //filtro de torres
                    //voltaje
                    const lineas = linesFilter.filter(l => l.enableFilter);
                    let v132 = voltageLevel[0] ? lineas.filter(t => t.voltageLevel === "132") : [];
                    let v33 = voltageLevel[1] ? lineas.filter(t => t.voltageLevel === "33") : [];
                    let v13_2 = voltageLevel[2] ? lineas.filter(t => t.voltageLevel === "13,2") : [];
                    let v220 = voltageLevel[3] ? lineas.filter(t => t.voltageLevel === "220") : [];
                    filtradas = [].concat(v132).concat(v33).concat(v13_2).concat(v220);
                }

                //se completan lista de torres
                let listaTorresFiltradas = [];
                if (!filtradas.length) {
                    setListaTorres([]);
                }
                else {
                    let datosTorres = listaTorresCompleta;

                    const idsFiltradas = filtradas.map(line => line.id);
                    datosTorres = listaTorresCompleta.filter(tower => tower.lines.map(tl => tl.line_id).some(r => idsFiltradas.indexOf(r) >= 0));

                    //me quedo con el riesgo mas alto segun las lineas activas
                    datosTorres.forEach(tower => {
                        tower.condition = 'NO_REVISION';
                        tower.lines
                            .filter(towerLine => idsFiltradas.includes(towerLine.line_id))
                            .forEach(towerLine => {
                                if (esMayorRiesgo(towerLine.condition, tower.condition)) {
                                    tower.condition = towerLine.condition;
                                }
                            });
                    });

                    //filtro estado revisiones
                    if (revState) {
                        let estructurasFiltered = [];
                        let sinRevision = revState[0] ? datosTorres.filter(t => t.condition === "NO_REVISION") : [];
                        let okRevision = revState[1] ? datosTorres.filter(t => t.condition === "NORMAL") : [];
                        let lowRisk = revState[2] ? datosTorres.filter(t => t.condition === "LOW_RISK") : [];
                        let mediumRisk = revState[3] ? datosTorres.filter(t => t.condition === "MEDIUM_RISK") : [];
                        let highRisk = revState[4] ? datosTorres.filter(t => t.condition === "HIGH_RISK") : [];

                        estructurasFiltered = estructurasFiltered.concat(sinRevision)
                            .concat(okRevision).concat(lowRisk).concat(mediumRisk).concat(highRisk);
                        estructurasFiltered = estructurasFiltered.sort((t1, t2) => t1.order - t2.order);
                        datosTorres = estructurasFiltered;
                    }

                    //filtro cantidad incidencias
                    if (issueCount >= 0) {
                        let issuesTowerFilter = datosTorres.filter(t => t.issuesCount >= Number.parseInt(issueCount));
                        datosTorres = issuesTowerFilter;
                    }

                    listaTorresFiltradas.push.apply(listaTorresFiltradas, datosTorres);
                    setListaTorres(listaTorresFiltradas)
                }

                //lineas deshabilitadas
                let lineasDeshabilitadas = linesFilter.filter(l => !l.enableFilter);
                if (lineasDeshabilitadas.length) {
                    getLinePaths(lineasDeshabilitadas.map(l => l.id), VOLTAGE_LEVEL)
                        .then((res: any) => {
                            setListaLineasDeshbilitadas(res.data);
                        });
                }
                else {
                    setListaLineasDeshbilitadas([]);
                }
            }
        }
    }, [filter, listaTorresCompleta]);

    useEffect(() => {
        if(lineData) {
            setCenterMap(lineData.centerMap);
            setZoomMap(lineData.zoomMap);
            setTorreSeleccionada(lineData.marker);
        }
    }, [lineData]);

    const onLoad = React.useCallback(
        (mapInstance) => {
            mapRef.current = mapInstance;
        }, []
    );



    const handleClickOnTower = (tower) => {
        handleClickTower(tower.id);
        setCenterMap({ 
            lat: parseFloat(tower.latitude), 
            lng: parseFloat(tower.longitude) 
        });
        setZoomMap(17);
        setTorreSeleccionada(tower);
    }

    return (
        <>
            <LoadingOverlay active={loading !== ''} spinner text={loading + '...'}>
                {
                    isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={centerMap}
                            zoom={zoomMap}
                            onLoad={onLoad}
                            options={{streetViewControl: false}}                
                        >
                            {/* LINEAS DESHABILITADAS */
                                !listaLineasDeshabilitadas ? <></> :
                                    listaLineasDeshabilitadas.map(line => {
                                        return (
                                            <Polyline
                                                key={line.id + '-dis'}
                                                path={line.path}
                                                options={{
                                                    strokeColor: 'gray',
                                                    strokeWeight: 4,
                                                    strokeOpacity: 0.5
                                                }}
                                            />
                                        )
                                    })
                            }
                            {/* LISTA DE TRAMOS */
                                !listaTramos? <></> :
                                    listaTramos.map(line => {
                                        return (
                                            <Polyline
                                                key={line.id + "-l"}
                                                path={line.path}
                                                options={{
                                                    strokeColor: line.color,
                                                    strokeWeight: 6
                                                }}
                                            />
                                        );
                                    })
                            }
                            {/* LISTA DE TORRES */
                                listaTorres.map((torre: any) => {
                                    let url = `./assets/icons/${torre.condition}.png`;
                                    const selected = torreSeleccionada && torre.id === torreSeleccionada.id;
                                    return (
                                        <Marker
                                            key={torre.id}
                                            position={{ lat: parseFloat(torre.latitude), lng: parseFloat(torre.longitude) }}
                                            icon={{
                                                url: url,
                                                scaledSize: selected ? new google.maps.Size(60, 60) : new google.maps.Size(30, 30)
                                            }}
                                            onMouseOver={() => setTorreHover(torre)}
                                            onMouseOut={() => setTorreHover(null)}
                                            onClick={() => handleClickOnTower(torre)}
                                        >
                                        </Marker>
                                    )
                                })
                            }
                
                            {/* INFO WINDOWS */
                                torreHover ?
                                    <InfoWindow
                                        position={{ lat: parseFloat(torreHover.latitude), lng: parseFloat(torreHover.longitude) }}
                                        onCloseClick={() => setTorreHover(null)}
                                        options={{
                                            pixelOffset: new google.maps.Size(0, -30)
                                        }}
                                    >
                                        <div>
                                            <h6>{t('type')} <strong>{torreHover.type}</strong></h6>
                                            {
                                                torreHover.lines.map((lineData: any) => {
                                                    return (
                                                        <div key={lineData.line_id + "-linedata"} style={{ maxWidth: '300px', padding: '2px' }}>
                                                            <h6>{t('line')} <strong>{lineData.line_code}</strong></h6>
                                                            <h6>{t('structure')} <strong>{torreHover.description}</strong></h6>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </InfoWindow>
                                    : <></>
                            }
                            <></>
                        </GoogleMap>
                    ) : <></>
                }
            </LoadingOverlay>
            
        </>
    )
}

export { MapPage }