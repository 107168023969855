import { AuthorizedRoute, PermissionsProvider } from '@tshio/react-router-permissions'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Switch} from 'react-router-dom'
import { RootState } from '../../../setup'
import { authorizationStrategy, getRoles, loginAuthorizationStrategy, ROLE, RouteProps } from '../../routing/auth/roles'
import { UploadFileImages } from './components/UploadFileImages'
import { UploadFileIssue } from './components/UploadFileIssue'
import { UploadFileStructure } from './components/UploadFileStructure'
import { UploadFileVideos } from './components/UploadFileVideos'

const routes: RouteProps[] = [
  { path: '/upload/structure', component: UploadFileStructure, role: ROLE.ROLE_ADMIN },
  { path: '/upload/issue', component: UploadFileIssue, role: ROLE.ROLE_ADMIN },
  { path: '/upload/image', component: UploadFileImages, role: ROLE.ROLE_ADMIN },
  { path: '/upload/video', component: UploadFileVideos, role: ROLE.ROLE_OPERATOR },
]

const UploadsPage: React.FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  
  return (
    <Switch>
      <PermissionsProvider permissions={getRoles(user.role)} authorizationStrategy={authorizationStrategy}>
        {
          routes.map((route, idx) => (
            route.role ? 
            <AuthorizedRoute key={idx} path={route.path} requires={route.role}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute> 
            :
            <AuthorizedRoute key={idx} path={route.path} authorizationStrategy={loginAuthorizationStrategy}>
              {({ isAuthorized }) => (isAuthorized ? <route.component /> : <Redirect from="/" to="/error/401" />)}
            </AuthorizedRoute>
          ))
        }
      </PermissionsProvider>
    </Switch>
  )
}

export default UploadsPage
