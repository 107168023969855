import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const UPLOADS = `${REACT_APP_API_URL}/upload-data/`
export const MULTIMEDIA = `${REACT_APP_API_URL}/multimedia/`

export function uploadStructures(data) {
  return axios.post<{result: any}>(UPLOADS + 'structure', data, { headers: {"Content-Type": "multipart/mixed"} });
}

export function uploadIssues(data) {
  return axios.post<{result: any}>(UPLOADS + 'issues', data, { headers: {"Content-Type": "multipart/mixed"} });
}

export function uploadImages(data) {
  return axios.post<{result: any}>(MULTIMEDIA + 'upload-photo', data, { headers: {"Content-Type": "multipart/mixed"}, responseType: 'blob' });
}

export function uploadVideos(data) {
  return axios.post<{result: any}>(MULTIMEDIA + 'upload-video', data, { headers: {"Content-Type": "multipart/mixed"}, responseType: 'blob' });
}
