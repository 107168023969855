import React, { useEffect, useState } from 'react'
import { Alert, Carousel } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { Player } from './revision/Player';
import ModalImage from "react-modal-image";

const RevisionDetails: React.FC<any> = ({ revisions }) => {

  const [index, setIndex] = useState(0);
  const [indexIssue, setIndexIssue] = useState(0);
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  useEffect(() => {
    setIndex(0);
    setIndexIssue(0);
  }, [revisions]);

  const { t } = useTranslation();
  const appURL = process.env.REACT_APP_MULTIMEDIA_URL;

  const updateIndex = (direction) => {
    let nextIndex;
    if (direction === 'prev') {
      nextIndex = index === 0 ? revisions.length - 1 : index - 1;
    }
    else {
      nextIndex = index === revisions.length - 1 ? 0 : index + 1;
    }
    setIndex(nextIndex);
  }

  const updateIndexIssue = (direction, issues) => {
    let nextIndex;
    if (direction === 'prev') {
      nextIndex = indexIssue === 0 ? issues.length - 1 : indexIssue - 1;
    }
    else {
      nextIndex = indexIssue === issues.length - 1 ? 0 : indexIssue + 1;
    }
    setIndexIssue(nextIndex);
  }

  const getNameCondition = (condition) => {
    switch (condition) {
      case 'LOW_RISK':
        return 'BAJO';
      case 'MEDIUM_RISK':
        return 'MEDIO';
      case 'HIGH_RISK':
        return 'ALTO';
      default:
        return 'NORMAL';
    }
  }

  const getColor = (condition) => {
    switch (condition) {
      case 'HIGH_RISK':
        return 'red';
      case 'MEDIUM_RISK':
        return 'orange';
      case 'LOW_RISK':
        return 'yellow';
      default:
        return 'green';
    }
  }

  const obtenerUrlImagen = issue => {
    let urlLogo = '/imagenes/logo.png'
    if (issue && issue.photo_name_1) {
      let url = appURL + issue.photo_name_1;
      return url;
    }
    return urlLogo;
  }

  const obtenerUrlVideo = revision => {
    let urlLogo = '/imagenes/logo.png'
    if (revision && revision.video_name) {
      let url = appURL + revision.video_name;
      return url;
    }
    return urlLogo;
  }

  const getIssueStatus = status => {
    switch(status) {
      case 'REPAIRED': return t('repaired').toUpperCase();
      case 'NOT_REPAIRED': return t('notRepaired').toUpperCase();
      case 'SCHEDULED': return t('scheduled').toUpperCase();
    }
  }

  return (
    <Carousel className='revosion-details p-0'
      activeIndex={index}
      controls={false}
      interval={null}
      indicators={false}>
      {
        revisions.map((lastRevision, index) => (
          <Carousel.Item key={lastRevision.id} className='p-0'>
            <Alert variant="secondary" className="mb-0 p-2">
              <div className="text-center">
                <h5>
                  {t('revision').toUpperCase()}
                  <span className="alert-link"> N°{lastRevision.number} {index + 1}/{revisions.length}</span>
                </h5>
              </div>
              {
                revisions.length === 1 ? null :
                  <div>
                    <button className="carousel-control-prev" onClick={() => updateIndex('prev')}>
                      <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" onClick={() => updateIndex('next')}>
                      <span className="carousel-control-next-icon"></span>
                    </button>
                  </div>
              }
            </Alert>
            <Alert variant="secondary" className="mb-0 p-2">
              <div className='d-flex justify-content-between'>
                <div>{t('date')}</div>
                <div className='fw-bold'>{lastRevision.date}</div>
              </div>
              <div className='d-flex justify-content-between'>
                <div>{t('levelRisk')}</div>
                <div style={{ backgroundColor: getColor(lastRevision.condition), width: '20%' }}></div>
                <div className='fw-bold'>{getNameCondition(lastRevision.condition)}</div>
              </div>
            </Alert>
            <hr className='my-1' />
            <p className="text-center my-auto">Incidencias</p>
            {
              !lastRevision.issues || !lastRevision.issues.length ?
                <Alert variant="success">
                  <h5 className='text-center'>{t('noIncidents').toUpperCase()}</h5>
                </Alert> :
                <React.Fragment>
                  <Carousel
                    key={'issues'}
                    activeIndex={indexIssue}
                    controls={false}
                    interval={null}
                    indicators={false}>
                    {
                      lastRevision.issues.map((issue, indexIssue) => {
                        return (
                          <Carousel.Item key={issue.id} style={{ paddingRight: '5px' }}>
                            <div key={issue.id}>
                              <Alert variant="secondary">
                                <div className="text-center">
                                  <h5>
                                    <i className="fa fa-circle mr-2 ml-2" style={{ color: getColor(issue.risk_level) }}></i>
                                    <span>{t('incident').toUpperCase()}</span>
                                    <span className="alert-link"> N°{indexIssue+1}/{lastRevision.issues.length}</span>
                                  </h5>
                                </div>

                                <h4 className="text-center mb-0">
                                  {issue.status === 'REPAIRED' ?
                                    <span data-toggle="tooltip" title={issue.resolutionComment}>{t('resolved')} <span className="fa fa-info-circle"></span> </span>
                                    : null}
                                </h4>
                                {
                                  lastRevision.issues.length === 1 ? null :
                                    <div>
                                      <button className="carousel-control-prev" onClick={() => updateIndexIssue('prev', lastRevision.issues)}>
                                        <span className="carousel-control-prev-icon"></span>
                                      </button>
                                      <button className="carousel-control-next" onClick={() => updateIndexIssue('next', lastRevision.issues)}>
                                        <span className="carousel-control-next-icon"></span>
                                      </button>
                                    </div>
                                }
                              </Alert>

                              <div className='d-flex justify-content-between'>
                                <div>{issue.dateFormatted}</div>
                                <div className='fw-bold'>{issue.statusDescription}</div>
                              </div>

                              <div className='d-flex flex-column ps-1'>

                                <div style={{ marginBottom: '10px' }}>
                                  <ModalImage
                                    small={obtenerUrlImagen(issue)}
                                    large={obtenerUrlImagen(issue)}
                                    alt={issue.observations} hideZoom={false}
                                  />
                                </div>
                                <Player
                                  open={isOpenVideo}
                                  toggleModal={() => setIsOpenVideo(!isOpenVideo)}
                                  url={obtenerUrlVideo(lastRevision)}
                                />
                                <button className='btn btn-sm btn-danger'
                                  type="button"
                                  disabled={!lastRevision.video_name}
                                  onClick={() => setIsOpenVideo(!isOpenVideo)}
                                >
                                  <i className="fa fa-play" /> {t('playVideo')}
                                </button>

                              </div>
                              <hr />
                              <Alert variant="secondary" className="mb-0 p-2">
                                <div className='d-flex justify-content-between'>
                                  <div>{t('state')}</div>
                                  <div className='fw-bold'>{issue.issue_status ? getIssueStatus(issue.issue_status) : "-"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div>{t('revisionObs')}</div>
                                  <div className='fw-bold'>{issue.observations ? issue.observations : "-"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div>{t('resolutionObs')}</div>
                                  <div className='fw-bold'>{issue.resolutionComment ? issue.resolutionComment : "-"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div>{t('type')}</div>
                                  <div className='fw-bold'>{issue.issueCode ? issue.issueCode.description : "-"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div>{t('code')}</div>
                                  <div className='fw-bold'>{issue.issueCode ? issue.issueCode.code_name : "-"}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                  <div>{t('videoTime')}</div>
                                  <div className='fw-bold'>{issue.video_time ? issue.video_time : "-"}</div>
                                </div>
                              </Alert>
                              <hr />
                            </div>
                          </Carousel.Item>
                        )
                      })
                    }
                  </Carousel>
                </React.Fragment>
            }
          </Carousel.Item>
        ))
      }
    </Carousel>
  )
}

export { RevisionDetails }