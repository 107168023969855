import React, { useEffect, useState } from 'react'
import { Alert, Carousel } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import ReactSlidingPane from 'react-sliding-pane';
import { RevisionDetails } from './RevisionDetails';

const SlideDetailsPane: React.FC<any> = ({ isOpen = false, detailsData, onChange }) => {

  const [open, setOpen] = useState(isOpen);
  const [data, setData] = useState<any>(null);
  const [index, setIndex] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setData(detailsData);
    setIndex(0);
  }, [detailsData]);

  const updateIndex = (direction) => {
    let nextIndex;
    if (direction === 'prev') {
      nextIndex = index === 0 ? data.lines.length - 1 : index - 1;
    }
    else {
      nextIndex = index === data.lines.length - 1 ? 0 : index + 1;
    }
    setIndex(nextIndex);
  }

  return (
    <ReactSlidingPane
      className="map-slider-pane details-sliding-pane p-0"
      overlayClassName="custom-overlay-class"
      isOpen={open}
      from="left"
      width="400px"
      title={t('towerDetails')}
      onRequestClose={() => {
        setOpen(false);
        onChange(false);
      }}
    >
      <React.Fragment>
        {
          data ?
            <div key={data.id}>
              <Carousel className='tower-details p-0'
                activeIndex={index}
                controls={false}
                interval={null}
                indicators={false}
              >
                {
                  data.lines.map((towerLine, index) => (
                    <Carousel.Item key={towerLine.line.id} className='p-0'>
                      <div key={towerLine.id}>
                        <p className="text-center m-0">{t('lineName')}</p>
                        <Alert variant='primary' className="p-0 mb-0">
                          <div className="text-center">
                            <h5>{towerLine.line?.name}</h5>
                            {data.lines.length === 1 ? null :
                              <span className="alert-link"> N° {index + 1}/{data.lines.length}</span>
                            }
                          </div>
                          {
                            data.lines.length === 1 ? null :
                              <div>
                                <button className="carousel-control-prev" onClick={() => updateIndex('prev')}>
                                  <span className="carousel-control-prev-icon"></span>
                                </button>
                                <button className="carousel-control-next" onClick={() => updateIndex('next')}>
                                  <span className="carousel-control-next-icon"></span>
                                </button>
                              </div>
                          }
                        </Alert>
                        <hr className='my-1' />
                        <div key={data.id + '-' + towerLine.id}>
                          <p className="text-center my-auto"> {t('structureData')} </p>
                          <Alert variant="secondary" className="mb-0 p-2">
                            <div className='d-flex justify-content-between'>
                              <div>{t('name')}</div>
                              <div className='fw-bold'>{data.description}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div>{t('type')}</div>
                              <div className='fw-bold'>{data.type}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div>{t('latitude')}</div>
                              <div className='fw-bold'>{data.latitude?.slice(0, 8)}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <div>{t('longitude')}</div>
                              <div className='fw-bold'>{data.longitude?.slice(0, 8)}</div>
                            </div>
                          </Alert>
                        </div>
                        <hr className='my-1' />
                        <p className="text-center my-auto">Revisiones </p>
                        {
                          !towerLine.revisions || towerLine.revisions.length === 0 ?
                            <Alert variant="secondary" className="text-center">
                              {t('unreviewed').toUpperCase()}
                            </Alert> :
                            <React.Fragment>
                              <RevisionDetails revisions={towerLine.revisions}></RevisionDetails>
                            </React.Fragment>
                        }
                      </div>
                    </Carousel.Item>
                  ))
                }
              </Carousel>
            </div>
            : <></>
        }
      </React.Fragment>
    </ReactSlidingPane>
  )
}

export { SlideDetailsPane }