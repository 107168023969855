/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, Formik } from 'formik';
import { FC, useMemo, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { mostrarError, mostrarExito } from '../../../core/EmisorMensajes';
import { deleteUser, getUsers, resetPassword, saveUser } from './redux/ConfigUsersCRUD';
import * as Yup from "yup";
import { MASCARAS } from '../../../core/FuncionesCampos';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { i18nTable } from '../../../core/Funciones';
const { REACT_APP_API_URL, REACT_APP_EMPRESA } = process.env

const initalValues = { name: '', surname: '', email: '', role: '', username: '' }
const campos = {
  name: {
    label: "name",
    placeholder: "",
    tipo: "text",
    ancho: 12,
    mascara: "soloLetrasEspacio",
    obligatorio: true,
  },
  surname: {
    label: "surname",
    placeholder: "",
    tipo: "text",
    ancho: 12,
    mascara: "soloLetrasEspacio",
    obligatorio: true,
  },
  email: {
    label: "email",
    placeholder: "",
    tipo: "email",
    ancho: 12,
    obligatorio: true,
  },
  role: {
    label: "role",
    placeholder: "selectOneOption",
    tipo: "select",
    ancho: 6,
    opciones: [
      { id: 'ROLE_OBSERVATOR', descripcion: 'OBSERVADOR' },
      { id: 'ROLE_OPERATOR', descripcion: 'OPERADOR' },
      { id: 'ROLE_ADMIN', descripcion: 'ADMIN' },
    ],
    obligatorio: true,
  },
  username: {
    label: "username",
    placeholder: "",
    tipo: "text",
    ancho: 6,
    mascara: "soloLetrasNumeros",
    obligatorio: true,
    soloLectura: false
  },
};
const getRoleName = (role) => {
  switch (role) {
    case 'ROLE_ADMIN': return 'ADMINISTRADOR';
    case 'ROLE_OPERATOR': return 'OPERADOR';
    case 'ROLE_OBSERVATOR': return 'OBSERVADOR';
  }
}

const ConfigUsersWrapper: FC = () => {
  const [users, setUsers] = useState([]);
  const [popupUser, setPopupUser] = useState(false);
  const [popupConfirmacion, setPopupConfirmacion] = useState(false);
  const [userSeleccionado, setUserSeleccionado] = useState(null);
  const [popupConfirmacionMode, setPopupConfirmacionMode] = useState('');
  const [refreshData, setRefreshData] = useState(true);
  const { t } = useTranslation();

  // Validation schema
  const UserSchema = Yup.object().shape({
    name: Yup.string().required(t('campoRequerido')).matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')),
    surname: Yup.string().required(t('campoRequerido')).matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')),
    email: Yup.string().optional().email(t('emailFormatInvalid')),
    role: Yup.string().required(t('campoRequerido')),
    username: Yup.string().required(t('campoRequerido')).matches(MASCARAS.soloLetrasNumeros, t('soloLetrasNumeros')),
  });

  useMemo(() => {
    let cancel = false;

    if (refreshData) {
      getUsers().then((response: any) => {
        if (cancel) return;

        setUsers(response.data);
        setRefreshData(false);
      })
    }

    return () => {
      cancel = true;
    }
  }, [refreshData])

  const toggleUser = (user) => {
    setUserSeleccionado(user);
    setTimeout(() => {
      setPopupUser(true);
    });
  }

  const deleteUsuario = () => {
    if (userSeleccionado) {
      deleteUser(userSeleccionado.id)
        .then(res => {
          if (res.status === 200) {
            mostrarExito("El usuario se ha eliminado correctamente");
            setTimeout(() => {
              setRefreshData(true);
              window.location.reload();
            }, 1000);
          }
        })
        .catch(err => {
          mostrarError(err);
        });

      return () => { setPopupConfirmacionMode(''); setPopupConfirmacion(false); setUserSeleccionado(null) };
    }
  }

  const cambiarContraseña = () => {
    if (userSeleccionado) {
      resetPassword(userSeleccionado.username)
        .then(res => {
          if (res.status === 200) {
            mostrarExito("El usuario se ha modificado correctamente!");
            setPopupConfirmacionMode('');
            setPopupConfirmacion(false);
            setPopupUser(false);
            setUserSeleccionado(null);
            setRefreshData(true);
          }
        })
        .catch(err => {
          mostrarError(err?.response?.data?.message);
        });
    }
  }

  const saveUserPopup = (values) => {
    const userId = userSeleccionado ? userSeleccionado.id : null;
    let user = {
      ...values,
      id: userId,
      newPassword: userId != null ? false : true,
      empresa: REACT_APP_EMPRESA,
      urlApi: REACT_APP_API_URL + '/'
    }

    delete user['password']
    saveUser(user)
      .then(res => {
        if (res.status === 200) {
          mostrarExito("El usuario se ha actualizado correctamente!");
          setPopupUser(false);
          setRefreshData(true);
        }
      })
      .catch(err => {
        mostrarError(err?.response?.data?.message);
      });
  };

  const toggleConfirmar = (user, mode, visible) => {
    setUserSeleccionado(user);
    setPopupConfirmacionMode(mode);
    setTimeout(() => {
      setPopupConfirmacion(visible); 
    });
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <Tabs defaultActiveKey="usuarios">
          <Tab eventKey="usuarios" title={t('users')}>
            <div className='d-flex justify-content-end'>
              <button
                className="btn btn-success btn-sm"
                onClick={() => toggleUser(initalValues)}
              >
                <i className="fa fa-plus" /> {t('addUser')}
              </button>
            </div>
            <ReactDataGrid
              idProperty="id"
              style={{ minHeight: 150, height: '78vh', overflowY: 'auto' }}
              columns={[
                { name: "name", header: t('name'), defaultFlex: 1 },
                { name: "surname", header: t('surname'), defaultFlex: 1 },
                { name: "username", header: t('username'), defaultFlex: 1 },
                {
                  name: "role", header: t('role'), defaultFlex: 1,
                  render: ({ data }) => getRoleName(data.role)
                },
                {
                  name: 'actions', header: t('actions'), defaultFlex: 1,
                  render: ({ data }) =>
                    <div className="p-0 text-center">
                      <button className="btn btn-outline-warning btn-lg" onClick={() => toggleUser(data)}>
                        <span className=" fa fa-edit" />
                      </button>
                      <button className="btn btn-outline-danger btn-lg" onClick={() => toggleConfirmar(data, 'delete', true)}>
                        <span className="fa fa-trash" />
                      </button>
                    </div>
                },
              ]}
              dataSource={users}
              i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
            />
          </Tab>
          <Tab eventKey="roles" title={t('roles')}>
            <ReactDataGrid
              idProperty="id"
              style={{ minHeight: 150, height: '78vh', overflowY: 'auto' }}
              columns={[
                { name: "role", header: t('role'), defaultFlex: 1 },
                { name: "code", header: t('code'), defaultFlex: 1 },
                { name: "description", header: t('description'), defaultFlex: 1 },
              ]}
              dataSource={[
                { role: 'OBSERVADOR', code: 'ROLE_OBSERVATOR', description: 'Acceso limitado. Sólo puede consultar información.' },
                { role: 'OPERADOR', code: 'ROLE_OPERATOR', description: 'Acceso parcial. Puede crear reportes y resolver órdenes de trabajo' },
                { role: 'ADMINISTRADOR', code: 'ROLE_ADMIN', description: 'Acceso total. Alta de estructuras, incidencias, órdenes de trabajo' }
              ]}
              i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
            />
          </Tab>
        </Tabs>
      </div>

      {
        userSeleccionado ? 
        <>
        <Modal show={popupConfirmacion} onHide={() => {}} centered size="lg">
          <Modal.Header><Modal.Title>Confirmación</Modal.Title></Modal.Header>
          <Modal.Body>
            <label>
              {popupConfirmacionMode === 'delete' ?
                "Seguro que desea eliminar el usuario?"
                : "¿Está seguro que quiere resetear la contraseña del usuario?"}
            </label>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="m-1 btn btn-sm" onClick={() => toggleConfirmar(null, '', false)}>
              <i className="fa fa-times" />{" " + t('cancel')}
            </button>
            <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => popupConfirmacionMode === 'delete' ? deleteUsuario() : cambiarContraseña()}>
              <i className="fa fa-save" />{" " + t('confirm')}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={popupUser} onHide={() => {}} centered size="lg">
          <Modal.Header><Modal.Title>Usuario</Modal.Title></Modal.Header>
          <Formik
            initialValues={userSeleccionado}
            validationSchema={UserSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                saveUserPopup(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, isSubmitting, handleChange, handleSubmit }) => (
              <form>
                <Modal.Body>
                  <div className="form-group row">
                    {//Listamos todos los campos que declaramos en el estado
                      Object.keys(campos).map(indice => {
                        return (
                          <div className={"col-lg-" + campos[indice].ancho} key={indice}>
                            <div className="form-group">
                              <label htmlFor={indice}>
                                {t(campos[indice].label)} {campos[indice].obligatorio ? "*" : ""}
                              </label>
                              {
                                indice !== 'role' ?
                                  <input
                                    className='form-control'
                                    name={indice}
                                    type={campos[indice].tipo}
                                    value={values[indice] === null ? undefined : values[indice]}
                                    onChange={handleChange}
                                    readOnly={campos[indice].soloLectura}
                                    placeholder={campos[indice].placeholder}
                                  />
                                  :
                                  <select
                                    className='form-select'
                                    name={indice}
                                    value={values[indice] === null ? undefined : values[indice]}
                                    onChange={handleChange}>
                                    <option>{t('selectOneOption')}</option>
                                    {
                                      campos[indice].opciones.map(opcion => (
                                        <option value={opcion.id} key={opcion.id}>{t(opcion.descripcion)}</option>
                                      ))
                                    }
                                  </select>
                              }
                              <ErrorMessage name={indice} component="div" className="form-error-message" />
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                  {
                    userSeleccionado.id ? null :
                      <p>{"El usuario recibirá la contraseña inicial a su correo electrónico."}</p>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="m-1 btn btn-secondary btn-sm" onClick={() => setPopupUser(false)}>
                    <i className="fa fa-times" />{" " + t('cancel')}
                  </button>
                  {
                    userSeleccionado.id ?
                      <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => toggleConfirmar(userSeleccionado, 'reset', true)} disabled={isSubmitting}>
                        <i className="fa fa-sync" />{" " + t('resetPassword')}
                      </button> : null
                  }
                  <button type="button" className="btn btn-primary btn-sm m-1" onClick={() => handleSubmit()} disabled={isSubmitting}>
                    <i className="fa fa-save" />{" " + t('save')}
                  </button>
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal>
        </> : null
      }
    </div>
  );
}

export { ConfigUsersWrapper }
