import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from "react-i18next";
import { searchData } from "../redux/MapCRUD";

const SearchBar: React.FC<any> = ({ onChangeSearchBar }) => {
    const [pattern, setPattern] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    let _cache = {};
    const { t } = useTranslation();

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pattern])

    const handleSearch = () => {
        if(pattern.length >= 3) {
            if (_cache[pattern]) {
                setOptions(_cache[pattern].options);
                return;
            }
    
            setLoading(true);
            searchData(pattern)
            .then((resp: any) => {
                _cache[pattern] = { ...resp, page: 1 };
                setLoading(false);
                setOptions(resp.data);
            });
        }
    }

    const handlePagination = (e, shownResults) => {
        const cachedQuery = _cache[pattern];

        if (
            cachedQuery.options.length > shownResults ||
            cachedQuery.options.length === cachedQuery.total_count
        ) {
            return;
        }

        setLoading(true);
        const page = cachedQuery.page + 1;

        searchData(pattern)
        .then((resp: any) => {
            const options = cachedQuery.options.concat(resp.data);
            _cache[pattern] = { ...cachedQuery, options, page };
            setLoading(false);
            setOptions(options);
        });
    }

    return (
        <AsyncTypeahead
            isLoading={loading}
            options={options}
            id="buscador"
            labelKey={(option: any) => `${option.type} - ${option.description}`}
            maxResults={100 - 1}
            minLength={2}
            onChange={(selected) => onChangeSearchBar(selected)}
            onInputChange={(value) => setPattern(value)}
            onPaginate={(e, shownResults) => handlePagination(e, shownResults)}
            onSearch={() => handleSearch()}
            paginate
            autoFocus
            ignoreDiacritics
            placeholder={t("searchPlaceholder")}
            promptText="Buscando..."
            searchText="Buscando..."
            emptyLabel="No hay resultados"
            useCache={true}
            style={{ width: '100%', height: '40px' }}
        />
    );
}

export { SearchBar };