import React, { useCallback, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

import moment from 'moment';
import { getTeams, ordenarLista } from "../../../pages/scheduler/redux/SchedulerCRUD";
import { mostrarError, mostrarExito, mostrarWarning } from "../../../../core/EmisorMensajes";
import { Modal } from "react-bootstrap-v5";
import { useFormik } from "formik";
import * as Yup from "yup";
import { exportar, i18nTable } from "../../../../core/Funciones";
import { cancelWorkOrder, getWorkOrders, printWorkOrderReport, sendEmailWorkOrders, updateWorkOrders } from "../redux/WorkOrderCRUD";

window.moment = moment;

const gridStyle = { minHeight: 600, height: '80vh' };

type ManageWorkOrderProps = {
    schedule_date?: string,
    end_date_scheduled?: string,
    team_id?: number,
    end_date?: string
}
const initialValues = {
    team_id: 0, schedule_date: '', end_date_scheduled: '', end_date: ''
};
const propertiesField = {
    schedule_date: {
        min: moment().format('YYYY-MM-DD'),
        max: '',
        type: 'date'
    },
    end_date_scheduled: {
        min: '',
        max: '',
        type: 'date'
    },
    end_date: {
        min: '',
        max: '',
        type: 'date'
    }
}

const ManageWorkOrders = (props) => {
    const { t } = props;

    const [filas, setFilas] = useState([]);
    const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
    const [seleccionFilas, setSeleccionFilas] = useState([]);

    const [lines, setLines] = useState([]);
    const [teams, setTeams] = useState([]);
    const [issueCodes, setIssueCodes] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    const [teamSelected, setTeamSelected] = useState(null);

    const [alertaEnvioEmail, setAlertaEnvioEmail] = useState(false);
    const [alerta, setAlerta] = useState(false);
    
    const [popupEdicion, setPopupEdicion] = useState(false);
    const [ordenesEdicion, setOrdenesEdicion] = useState([]);

    const [refreshData, setRefreshData] = useState(true);

    const validationSchema = Yup.object().shape({
    });
    const formik = useFormik<ManageWorkOrderProps>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus();
            saveOTs(values);
            setSubmitting(false);    
        }
    });

    useEffect( () => {
        console.log('Props Changed');
        if(refreshData) {
            getWorkOrders('PROGRAMADA')
            .then((res: any) => {
                if(res.data?.error) {
                    throw(res.data.error);
                }
                const data = res.data;
                data.forEach(o => {
                    o.team_name = o.team?.name;
                    o.issue_number = o.issue?.number;
                    o.issuecode_name = o.issue?.issueCode?.name;
                });

                setFilas(data);
                
                const linesOptions = Array.from(new Set(data.map(r => r.line_name))).map(d => ({ label: d, id: d }));
                const lines = ordenarLista('label', linesOptions, 'asc');
                setLines(lines);

                const codesOptions = Array.from(new Set(data.map(r => r.issuecode_name))).map(d => ({ label: d, id: d }));
                const issueCodes = ordenarLista('label', codesOptions, 'asc');
                setIssueCodes(issueCodes);

                setRefreshData(false);
                setFilasSeleccionadas([]);
                setSeleccionFilas([]);
            })
            .catch(err => console.log(err))
        }
    }, [refreshData]);

    useEffect(() => {
        getTeams()
        .then((res: any) => {
            if(res.data){
                if(res.data && res.data.length) {
                    res.data.forEach(team => team.descripcion = team.name);
                    const teamsList = ordenarLista('descripcion', res.data, 'asc');
                    setTeamsList(teamsList);

                    const teamsOptions = Array.from(new Set(res.data.map(r => r.name))).map(d => ({ label: d, id: d }));
                    const teams = ordenarLista('label', teamsOptions, 'asc');
                    setTeams(teams);

                    if(teamsList.length > 0){
                        setTeamSelected(teamsList[0]);
                    }
                }
            }
        })
        .catch(err => {
            mostrarError(err);
        });
    }, [])

    const toggleEditarOT = (limpiar?) => {
        const ordenes = seleccionFilas;
        if(!ordenes.length) {
            mostrarWarning(t("mustSelectOT"));
        }
        else {
            if(limpiar){
                setOrdenesEdicion([]);
                setPopupEdicion(false);
                formik.setValues(initialValues);
            }
            else {
                //si se edita solo una, se puede pre-cargar la info            
                if(ordenes.length === 1) {
                    const orden = ordenes[0];
                    //min and max fecha fin posible
                    propertiesField.end_date.min = moment(orden.schedule_date).format('YYYY-MM-DD');
                    propertiesField.schedule_date.type = 'date';
                    propertiesField.end_date_scheduled.type = 'date';

                    formik.setValues({
                        schedule_date: orden.schedule_date,
                        end_date_scheduled: orden.end_date_scheduled,
                        team_id: orden.team_id,
                        end_date: orden.end_date ? orden.end_data : ''
                    })
                }
                else {
                    propertiesField.schedule_date.type = 'hidden';
                    propertiesField.end_date_scheduled.type = 'hidden';
                }
                setOrdenesEdicion(ordenes);
                propertiesField.end_date.max = moment().format('YYYY-MM-DD');

                setPopupEdicion(true);
            }
        }
    }

    const saveOTs = (valueForm) => {
        const ordenes = seleccionFilas;
        if(!ordenes.length) {
            mostrarWarning(t("mustSelectOT"));
        }
        else {
            const team_id = valueForm.team_id;
            const team = team_id ? teamsList.filter(t => t.id === parseInt(team_id))[0] : null;

            if(team) {
                let data = {
                    ids: ordenes.map(o => o.id),
                    team_id: team.id,
                    jefeCuadrilla: team.manager_name,
                    email: team.manager_email,
                    ...valueForm
                }
    
                updateWorkOrders(data)
                .then(res => {
                    if(res.status === 200){
                        mostrarExito(t("changesApplied"));
                        toggleEditarOT(true);
                        setTimeout(() => {
                            setRefreshData(true);
                        }, 500);
                    }
                })
                .catch(err => {
                    mostrarError(err);
                });
            }
        }     
    };

    const cancelarOTs = async () => {
        const valido = validarSeleccionTeam();
        if(valido) {
            const ordenes = seleccionFilas;
            if(!ordenes.length) {
                mostrarWarning(t("mustSelectOT"));
            }
            else {
                let ok = true;
                for (let i = 0; i < ordenes.length; i++) {
                    const ot = ordenes[i];
                    try {
                        await cancelWorkOrder(ot.id);
                    }
                    catch(err) {
                        ok = false;
                        setAlerta(false);
                        mostrarError(err);
                    }
                }

                if(ok){
                    mostrarExito(t("changesApplied"));
                    setAlerta(false);
                    enviarEmail(null)
                    setTimeout(() => {
                        setRefreshData(true)
                    }, 500);
                }
            }
        }
        else {
            setAlerta(false);
        }
    }

    const handleImprimirOTs = () => {
        const valido = validarSeleccionTeam();
        if(valido) {
            const ordenes = seleccionFilas;
            const data = {
                jefeCuadrilla: teamSelected.manager_name,
                ordenes
            }
            printWorkOrderReport(data)
            .then(res => {
                exportar(res.data, `reporte_resumen`);
            })
            .catch(err => {
                mostrarError(t("errorDefault"));
            });
        }
    }
    
    const enviarEmail = (e?, tipo?) => {
        const valido = validarSeleccionTeam();
        if(valido) {
            const ordenes = seleccionFilas;

            const data = {
                jefeCuadrilla: teamSelected.manager_name,
                email: teamSelected.manager_email,
                ordenes,
                template: 'detailOT'
            }
    
            sendEmailWorkOrders(data)
            .then(res => {
                if(res && res.data) {
                    mostrarExito("El email se ha enviado correctamente!");
                    if(!tipo) {
                        setAlertaEnvioEmail(false);
                    }
                }
            })
            .catch(err => {
                mostrarError(t("errorDefault"));
            });
        }    
        else {
            setAlertaEnvioEmail(false);
        }    
    }
 
    const columns = [
        { name: 'number', header: t('numberWO'), defaultFlex: 1, type: 'string', filterEditor: StringFilter },
        { name: 'team_name', header: t('team'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                dataSource: teams,
                wrapMultiple: false
            },
        },
        { name: 'line_name', header: t('line'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: lines,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'tower_name', header: t('structure'), defaultFlex: 1, type: 'text', filterEditor: StringFilter },
        { name: 'issuecode_name', header: t('incidentCode'), defaultFlex: 1, filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: t('all'),
                dataSource: issueCodes,
                multiple: true,
                wrapMultiple: false
            },
        },
        { name: 'issue_number', header: t('incidentNo'), defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
        { name: 'schedule_date', header: t('scheduledStartDate'), defaultFlex: 1, dateFormat: 'DD/MM/YYYY', type: 'date', 
            filterEditor: DateFilter,
            sort: (p1, p2) => p1.localeCompare(p2),
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'DD/MM/YYYY',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) => value ? moment(value).format(dateFormat) : '',
        },
        { name: 'end_date_scheduled', header: t('scheduledEndDate'), defaultFlex: 1, dateFormat: 'DD/MM/YYYY', type: 'date', filterEditor: DateFilter, 
            sort: (p1, p2) => p1.localeCompare(p2),
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'DD/MM/YYYY',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) => value ? moment(value).format(dateFormat) : '',
        },
    ];

    const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))

    const onSelectionChange = useCallback(({ selected }) => {
        const seleccionadas = Object.values(selected);
        setSeleccionFilas(seleccionadas);
        setFilasSeleccionadas(selected);
    }, [])

    const onColumnFilterValueChange = useCallback((filter) => {
        if(filter.columnId === 'team_name') {
            setSeleccionFilas([]);
            setFilasSeleccionadas([]);
        }
    }, [])

    const renderTable = () => {
        if(teams.length) {
            return(
                <ReactDataGrid
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    dataSource={filas}
                    i18n={i18n}
                    rowHeight={25}
                    defaultFilterValue={[
                        { name: 'number', operator: 'eq', type: 'string', value: undefined },
                        { name: 'team_name', operator: 'eq', type: 'select', value: teams.length ? teams[0].id : undefined },
                        { name: 'line_name', operator: 'inlist', type: 'select', value: undefined },
                        { name: 'tower_name', operator: 'contains', type: 'string', value: undefined },
                        { name: 'issuecode_name', operator: 'inlist', type: 'select', value: undefined },
                        { name: 'issue_number', operator: 'eq', type: 'number', value: undefined },
                        { name: 'schedule_date', operator: 'eq', type: 'date', value: undefined },
                        { name: 'end_date_scheduled', operator: 'eq', type: 'date', value: undefined }
                    ]}
                    selected={filasSeleccionadas}
                    checkboxColumn
                    onSelectionChange={onSelectionChange}
                    onColumnFilterValueChange={onColumnFilterValueChange}
                />
            )
        }
    }

    const setMaximosMinimosFechas = (indice, valuesForm, e) => {
        const nuevoValor = e.target.value;
        if(indice === 'schedule_date' || indice === 'end_date_scheduled') {
            if(indice === 'schedule_date') {
                let scheduled = moment();
                if(moment(nuevoValor).isAfter(moment())) {
                    scheduled = moment(nuevoValor);
                }
                const endDate = moment(valuesForm['end_date_scheduled']);
    
                propertiesField.schedule_date.min = moment().format('YYYY-MM-DD');
                propertiesField.schedule_date.max = endDate ? endDate.format('YYYY-MM-DD') : null;

                propertiesField.end_date_scheduled.min = scheduled.format('YYYY-MM-DD');
                propertiesField.end_date_scheduled.max = null;
            }
            else if(indice === 'end_date_scheduled') {
                let endScheduled = moment();
                if(moment(nuevoValor).isAfter(moment())) {
                    endScheduled = moment(nuevoValor);
                }
                const startDate = moment(valuesForm['schedule_date']);

                propertiesField.schedule_date.min = moment().format('YYYY-MM-DD');
                propertiesField.schedule_date.max = endScheduled.format('YYYY-MM-DD');

                propertiesField.end_date_scheduled.min = startDate ? startDate.format('YYYY-MM-DD') : null;
                propertiesField.end_date_scheduled.max = null;
            }
        }
    }

    const generarOpciones = (indice) => {
        let opciones = [];
        if(indice === "team_id") {
            opciones = teamsList;
        }

        return (
            <React.Fragment>
                <option value=""></option>
                {opciones.map(opcion => {
                return (
                    <option key={opcion.id} value={opcion.id}>
                    {opcion.descripcion}
                    </option>
                );
                })}
            </React.Fragment>
        );
    };

    const renderPopupEdicion = () => {
        return(
            <form onSubmit={formik.handleSubmit} className="form">
                <Modal show={popupEdicion} onHide={() => toggleEditarOT(true)} centered size="lg">
                    <Modal.Header><Modal.Title>{t("modifyWO")}</Modal.Title></Modal.Header>
                    <Modal.Body>                            
                        <div className="form-group row">
                            <div className={'col-lg-6'} key={'schedule_date'} 
                                style={{visibility: propertiesField.schedule_date.type === 'date' ? 'visible' : 'hidden'}}>
                                <div className="form-group">
                                    <label htmlFor={'schedule_date'}>
                                        {t('startDateSchedule')}
                                    </label>
                                    <input
                                        className="form-control"
                                        name='schedule_date'
                                        type='date'
                                        value={formik.values.schedule_date}
                                        onChange={e => {
                                            setMaximosMinimosFechas('schedule_date', formik.values, e);
                                            formik.handleChange(e);
                                        }}
                                        {...propertiesField.schedule_date}
                                    />
                                </div>
                            </div>
                            <div className={'col-lg-6'} key={'end_date_scheduled'}
                                style={{visibility: propertiesField.end_date_scheduled.type === 'date' ? 'visible' : 'hidden'}}>
                                <div className="form-group">
                                    <label htmlFor={'end_date_scheduled'}>
                                        {t('endDateSchedule')}
                                    </label>
                                    <input
                                        className="form-control"
                                        name='end_date_scheduled'
                                        type='date'
                                        value={formik.values.end_date_scheduled}
                                        onChange={e => {
                                            setMaximosMinimosFechas('end_date_scheduled', formik.values, e);
                                            formik.handleChange(e);
                                        }}
                                        {...propertiesField.end_date_scheduled}
                                    />
                                </div>
                            </div>
                            <div className={'col-lg-6'} key={'team_id'}>
                                <div className="form-group">
                                    <label htmlFor={'team_id'}>
                                        {t('team')}
                                    </label>
                                    <select
                                        className="form-select"
                                        name='team_id'
                                        value={formik.values.team_id}
                                        onChange={e => formik.handleChange(e)}
                                        {...formik.getFieldProps('team_id')}
                                    >
                                        {generarOpciones('team_id')}
                                    </select>
                                </div>
                            </div>
                            <div className={'col-lg-6'} key={'end_date'}>
                                <div className="form-group">
                                    <label htmlFor={'end_date'}>
                                        {t('endDate')}
                                    </label>
                                    <input
                                        className="form-control"
                                        name='end_date'
                                        type='date'
                                        value={formik.values.end_date}
                                        onChange={e => formik.handleChange(e)}
                                        {...propertiesField.end_date}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="m-1 btn btn-sm" disabled={formik.isSubmitting} onClick={() => toggleEditarOT(true)}>
                            <i className="fa fa-times" />{" "+t('cancel')}
                        </button>
                        <button type="button" className="m-1 btn btn-sm btn-primary" disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
                            <i className="fa fa-save" />{" "+t('save')}
                        </button>
                    </Modal.Footer>
                </Modal>
            </form>
        );
    }

    const validarSeleccionTeam = () => {
        const teamsArray = seleccionFilas.map(o => o.team_id);
        const teams = new Set(teamsArray);
        if(teams.size !== 1) {
            mostrarError("Las órdenes seleccionadas son de distinta cuadrilla");
            return false;
        }

        const team = teamsList.filter(t => t.id === teamsArray[0])[0];
        setTeamSelected(team);
        return true;
    }

    const toggleEnvioEmail = () => {
        validarSeleccionTeam();
        setAlertaEnvioEmail(!alertaEnvioEmail);
    }

    return(
        <>
            {
                teamsList && ordenesEdicion.length ? renderPopupEdicion() : null
            }
            <Modal show={alertaEnvioEmail}>
                <Modal.Header onHide={() => toggleEnvioEmail()}>
                    <Modal.Title>Atención</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {`Se enviará un email a la dirección ${teamSelected && teamSelected.manager_email} con el reporte de las órdenes de trabajo. ¿Desea continuar?`}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-success" onClick={() => enviarEmail()}>Si</button>
                    <button className="btn btn-sm" onClick={() => toggleEnvioEmail()}>No</button>
                </Modal.Footer>
            </Modal>
            <Modal show={alerta}>
                <Modal.Header onHide={() => setAlerta(!alerta)}>
                    <Modal.Title>Atención</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {`¿Está seguro que desea cancelar las órdenes de trabajo seleccionadas?`}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-success" onClick={() => cancelarOTs()}>Si</button>
                    <button className="btn btn-sm" onClick={() => setAlerta(!alerta)}>No</button>
                </Modal.Footer>
            </Modal>
            <div className='card'>
                <div className='card-body'>
                    <form>
                        <h3>{t('manageWO')}</h3>
                        {renderTable()}
                        <div className="row mt-2" style={{float: 'right'}}>
                            <div className="col-lg-12">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => toggleEditarOT()}
                                    disabled={!seleccionFilas.length}>
                                    <i className="fa fa-edit"></i>{" "}
                                    {t('changeOrders')}
                                </button>
                                <button type="button" className="ms-1 btn btn-sm btn-primary" onClick={() => setAlerta(true)}
                                    disabled={!seleccionFilas.length}>
                                    <i className="fa fa-times"></i>{" "}
                                    {t('cancelOrders')}
                                </button>
                                <button type="button" className="ms-1 btn btn-sm btn-primary" onClick={() => handleImprimirOTs()}
                                    disabled={!seleccionFilas.length}>
                                    <i className="fa fa-print"></i>{" "}
                                    {t('printOrders')}
                                </button>
                                <button type="button" className="ms-1 btn btn-sm btn-primary" disabled={!seleccionFilas.length}
                                    onClick={() => toggleEnvioEmail()}>
                                    <i className="fa fa-envelope"></i>{" "}
                                    {t('sendByEmail')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(ManageWorkOrders);