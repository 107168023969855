import React, {FC, useEffect, useState} from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { mostrarError, mostrarErrorJson, mostrarExito } from "../../../../core/EmisorMensajes";
import { addTeam, getTeams, removeTeam } from "../../../pages/scheduler/redux/SchedulerCRUD";
import { i18nTable } from "../../../../core/Funciones";
import { MASCARAS } from "../../../../core/FuncionesCampos";
import { Modal } from "react-bootstrap-v5";

type TeamProps = {
    id?: number,
    name?: string,
    manager_name?: string,
    manager_phone?: string,
    manager_email?: string,
    description?: string
}

const initialValues = {
    id: 0, name: '', manager_name: '', manager_phone: '', manager_email: '', description: ''
};

const Teams : FC = () => {
    const { t } = useTranslation();

    const [filas, setFilas] = useState([]);
    const [teamSelected, setTeamSelected] = useState<TeamProps>(initialValues);
    const [popupEdicion, setPopupEdicion] = useState(false);
    const [refresh, setRefresh] = useState(true);

    const validationSchema = Yup.object().shape({
        name: Yup.string().matches(MASCARAS.soloLetrasEspacioNumeros, t('soloLetrasEspacioNumeros')).required(t('campoRequerido')),
        manager_name: Yup.string().matches(MASCARAS.soloLetrasEspacio, t('soloLetrasEspacio')).required(t('campoRequerido')),
        manager_email: Yup.string().email(t('emailFormatInvalid')).required(t('campoRequerido')),
        manager_phone: Yup.string().matches(MASCARAS.soloNumeros, t('soloNumeros'))
    });
    const formik = useFormik<TeamProps>({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (fields, { setStatus, setSubmitting }) => {
            setStatus();
            let team = {
                id: teamSelected ? teamSelected.id : null,
                ...fields
            }
    
            addTeam(team)
            .then(res => {
                if(res.status === 200){
                    mostrarExito(t('changesApplied'));
                    toggleAddTeam(true);
                    setRefresh(true);
                }
            })
            .catch(err => {
                setSubmitting(false);
                mostrarError(err);
            });     
        }
    });

    useEffect(() => {
        if(refresh) {
            getTeams()
            .then((res: any) => {
                if(res.data){
                    setFilas(res.data);
                }
                setRefresh(false);
            })
            .catch(err => {
                mostrarError(err);
            });
        }

        return () => setRefresh(false);
    }, [refresh]);

    const toggleAddTeam = (limpiar) => {
        setPopupEdicion(!popupEdicion);

        if(limpiar){
            setTeamSelected(initialValues);
            formik.setValues(initialValues);
        }
    }

    const editTeam = (e, team) => {
        setTeamSelected(team);
        toggleAddTeam(false);
        formik.setValues(team);
    }

    const deleteTeam = (e, team) => {
        removeTeam(team.id)
        .then(res => {
          if(res.status === 200){
            mostrarExito(t('teamDeleted'));
            setRefresh(true);
          }
        })
        .catch(err => {
          mostrarErrorJson(err);
        });
    }

    let ACCIONES = [
        {
          handler: editTeam, color: "warning", icono: "fa fa-edit"
        },
        {
          handler: deleteTeam, color: "danger", icono: "fa fa-trash"
        }
    ]

    return (
        <div className='card'>
            <div className='card-body'>
                <h3>{t('teamsSettings')}</h3>
                <div className="d-flex flex-column align-items-end">
                    <button className="btn btn-success btn-sm" onClick={() => {toggleAddTeam(false)}}>
                        <i className="fa fa-plus" /> {t('addTeam')}
                    </button>
                </div>
                <ReactDataGrid
                    idProperty="id"
                    style={{ minHeight: 150, height: '80vh' }}
                    columns={[
                        { name: 'name', header: t('name'), defaultFlex: 1 },
                        { name: 'manager_name', header: t('teamLeader'), defaultFlex: 1 },
                        { name: 'manager_email', header: t('teamLeaderEmail'), defaultFlex: 1 },
                        { name: 'manager_phone', header: t('teamLeaderTelephoneNumber'), defaultFlex: 1 },
                        { name: 'acciones', header: t('actions'), defaultFlex: 1, 
                            render: ({ data }) => 
                                <div className="p-0 text-center">
                                    {
                                        ACCIONES.map(accion =>
                                            <button className={'btn btn-sm btn-outline-'+accion.color} key={accion.handler+''} onClick={() => accion.handler(null, data)}>
                                                <span className={accion.icono}></span>
                                            </button>
                                        )
                                    }
                                </div>
                        },
                    ]}
                    dataSource={filas}
                    i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                />
                <form className="form">
                    <Modal
                        show={popupEdicion}
                        onHide={() => toggleAddTeam(true)}
                        centered
                        size="lg">
                        <Modal.Header><Modal.Title>{t("team")}</Modal.Title></Modal.Header>
                        <Modal.Body>                            
                            <div className="form-group row">
                                <div className='col-lg-12' key='name'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>{t('name')}</label>
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="name"
                                            value={formik.values.name}
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name && 
                                            (<div className="form-error-message">{formik.errors.name}</div>)
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-12' key='manager_name'>
                                    <div className='form-group'>
                                        <label htmlFor='manager_name'>{t('responsibleName')}</label>
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="manager_name"
                                            value={formik.values.manager_name}
                                            {...formik.getFieldProps('manager_name')}
                                        />
                                        {formik.touched.manager_name && formik.errors.manager_name && 
                                            (<div className="form-error-message">{formik.errors.manager_name}</div>)
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-6' key='manager_phone'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>{t('responsiblePhone')}</label>
                                        <input
                                            className='form-control'
                                            type="text"
                                            name="manager_phone"
                                            value={formik.values.manager_phone}
                                            {...formik.getFieldProps('manager_phone')}
                                        />
                                        {formik.touched.manager_phone && formik.errors.manager_phone && 
                                            (<div className="form-error-message">{formik.errors.manager_phone}</div>)
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-6' key='manager_email'>
                                    <div className='form-group'>
                                        <label htmlFor='manager_email'>{t('responsibleEmail')}</label>
                                        <input
                                            className='form-control'
                                            type="email"
                                            name="manager_email"
                                            value={formik.values.manager_email}
                                            {...formik.getFieldProps('manager_email')}
                                        />
                                        {formik.touched.manager_email && formik.errors.manager_email && 
                                            (<div className="form-error-message">{formik.errors.manager_email}</div>)
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-12' key='description'>
                                    <div className='form-group'>
                                        <label htmlFor='description'>{t('description')}</label>
                                        <textarea
                                            className='form-control'
                                            name="description"
                                            value={formik.values.description}
                                            {...formik.getFieldProps('description')}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-primary btn-sm m-1" disabled={formik.isSubmitting}
                                onClick={() => formik.handleSubmit()}>
                                <i className="fa fa-save" />{" "+t('save')}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </form>
            </div>
        </div>
    );
}

export { Teams };