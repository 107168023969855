import React, { useState } from "react";
import { Card } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { uploadVideos } from "../redux/UploadsCRUD";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { i18nTable } from "../../../../core/Funciones";

const UploadFileVideos: React.FC  = () => {
    const [uploading, setUploading] = useState(false);
    const [resultadoUpload] = useState(null);
    const [filasResultado, setFilasResultado] = useState([]);

    const { t } = useTranslation();

    const subirFichero = () => {
        setUploading(true);
            
        filasResultado.forEach((archivo, index) => {
            let fileForm = new FormData();
            fileForm.append("file", archivo.file);
            fileForm.append("rev_number", archivo.revNumber);
            fileForm.append("tower_name", archivo.towerName);

            uploadVideos(fileForm)
            .then((res: any) => {
                if(res.data){
                    if(res.data){
                        const filas = [...filasResultado];
                        filas[index].uploaded =  t('yes');
                        setFilasResultado(filas)
                    }
                    setUploading(false);
                }
            })
            .catch(err => {
                const filas = [...filasResultado];
                filas[index].uploaded =  t('no');
                setFilasResultado(filas)
                setUploading(false);
            });
        })
    }

    const procesarFicheros = (archivos: FileList) => {
        if(archivos.length) {
            const filas = [];
            for (let index = 0; index < archivos.length; index++) {
                const archivo = archivos.item(index);

                let data = {
                    filename: archivo.name,
                    file: archivo,
                    revNumber: '',
                    issueNumber: '',
                    towerName: ''
                }

                const filename = data.filename.toLowerCase();
                const rev_split = filename.split('rev_');
                const index_ = rev_split[1].indexOf('_');
                if(rev_split.length === 2) {
                    data.revNumber = rev_split[1].slice(0, index_);
                }
                //const towerDesc = rev_split[1].slice(index_ + 1, rev_split[1].length);

                if(filename.indexOf('issue_') !== -1) {
                    const issue_split = filename.split('issue_');
                    if(issue_split.length === 2) {
                        const issue_tower_split = issue_split[1].split('_');
                        if(issue_tower_split.length >= 2) {
                            data.issueNumber = issue_tower_split[0];
                            const nameExt =  issue_tower_split[1].split('.');
                            data.towerName = nameExt[0];
                        }
                    }
                }
             
                filas.push(data);
            }

            setFilasResultado(filas);
        }
    }

    const removeFile = data => {
        const indice = filasResultado.findIndex(fila => fila.filename === data.filename);
        if(indice) {
            setFilasResultado(
                filasResultado.filter((value, index) => indice !== index)
            )
        }
    }

    return (  
        <div>
            <Card>
                <Card.Header>
                    <Card.Title><strong className="title-upload">{t('uploadRevisionVideos')}</strong></Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='form-group'>
                                <label htmlFor='file'>{t('file')}</label>
                                <input
                                    className='form-control'
                                    type="file"
                                    multiple={true}
                                    accept=".mp4, .webm"
                                    placeholder={t('selectOneFile')}
                                    onChange={(e) => procesarFicheros(e.target.files)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <ReactDataGrid
                                idProperty="id"
                                style={{ minHeight: 150, height: '42vh', overflowY: 'auto' }}
                                columns={[
                                    { name: "revNumber", header: t('revisionNo'), defaultFlex: 1 },
                                    { name: "towerName", header: t('structure'), defaultFlex: 1 },
                                    { name: "filename", header: t('filename'), defaultFlex: 1 },
                                    { name: "uploaded", header: t('sync'), defaultFlex: 1 },
                                    { name: "acciones", header: t('actions'), defaultFlex: 1, 
                                        render: ({ data }) =>
                                        <div className="p-0 text-center">
                                            <button className={'btn btn-sm btn-outline-danger'} onClick={() => removeFile(data)}>
                                                <span className="fa fa-trash"></span>
                                            </button>
                                        </div>
                                    },
                                ]}
                                dataSource={filasResultado}
                                i18n={Object.assign({}, ReactDataGrid.defaultProps.i18n, i18nTable(t))}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='form-group'>
                                <label htmlFor='file'>{t('result')}</label>
                                <textarea
                                    className='form-control'
                                    rows={3}
                                    value={resultadoUpload}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <button 
                        type="button" className="btn btn-sm btn-success m-1" disabled={uploading} onClick={() => subirFichero()}>
                        <i className="fa fa-check" /> {t('uploadFiles')}
                    </button>
                </Card.Footer>
            </Card>
        </div>
    );
}
 
export { UploadFileVideos };