import React from "react";
import { Modal } from "react-bootstrap-v5";
import ReactPlayer from 'react-player';

const Player: React.FC<any> = ({ open, toggleModal, url }) => {

  return (
    <Modal
      show={open}
      onHide={() => toggleModal()}
      size="lg"
      autoFocus={true}
      centered
      width="100%"
    >
      <ReactPlayer
        // Disable download button
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}

        // Disable right click
        onContextMenu={e => e.preventDefault()}

        // Your props
        url={url}
        className="react-player"
        controls
        width="100%"
        height="100%"
      />

    </Modal>
  );
}

export { Player };

