import axios from 'axios'

const { REACT_APP_API_URL } = process.env
export const REPORTS = `${REACT_APP_API_URL}/report/`
export const ZONES = `${REACT_APP_API_URL}/line/zones`
export const LINES = `${REACT_APP_API_URL}/line/all`
export const ISSUE = `${REACT_APP_API_URL}/issue/`
export const TOWER = `${REACT_APP_API_URL}/tower/`

export function getReportDataLineZone(filter) {
  return axios.post<{result: any}>(REPORTS + 'report-data-line-zone', filter);
}

export function getZones() {
  return axios.get<{result: any}>(ZONES);
}

export function getLines() {
  return axios.get<{result: any}>(LINES);
}

export function printReport(data) {
  return axios.post<{result: any}>(REPORTS + 'print-report-data-line-zone', data, { responseType: 'blob'});
}

export function getReport(id) {
  return axios.get<{result: any}>(REPORTS + '?id=' + id);
}

export function saveFavorite(data) {
  return axios.post<{result: any}>(REPORTS + 'save-report-favourite', data);
}

export function getReports(name?, includeAll?) {
  let url = REPORTS + 'list-favourites?name=' + name;
  if(name) {
    url += '&name=' + name;
  }
  if(includeAll) {
    url += '&includeAll=true';
  }
  return axios.get<{result: any}>(url);
}

export function eliminaReporte(id) {
  return axios.delete<{result: any}>(REPORTS + '?id=' + id);
}

export function getRevisionsPlans(lineId?) {
  return axios.get<{result: any}>(ISSUE + 'revision-plan-list?line=' + lineId);
}

export function getIssueDetails(filter) {
  return axios.post<{result: any}>(REPORTS + 'issues-detail', filter);
}

export function printReportIssues(filter) {
  return axios.post<{result: any}>(REPORTS + `issues-detail-print`, filter, { responseType: 'blob' });
}

export function getTowersLine(filter) {
  return axios.post<{result: any}>(TOWER + `find`, filter);
}

export function getReceivers() {
  return axios.get<{result: any}>(REPORTS + 'receiver');
}

export function saveReceiver(data) {
  return axios.post<{result: any}>(REPORTS + 'receiver', data);
}

export function removeReceiver(receiverId) {
  return axios.delete<{result: any}>(REPORTS + 'receiver?id=' + receiverId);
}

export function getGroups() {
  return axios.get<{result: any}>(REPORTS + 'group');
}

export function saveGroup(data) {
  return axios.post<{result: any}>(REPORTS + 'group', data);
}

export function removeGroup(groupId) {
  return axios.delete<{result: any}>(REPORTS + 'group?id=' + groupId);
}

export function enviarEmailReport(reportId) {
  return axios.get(REPORTS + "report-config/send-email?id=" + reportId)
}

export function getReportsConfig() {
  return axios.get<{result: any}>(REPORTS + 'report-config');
}

export function saveReportConfig(data) {
  return axios.post<{result: any}>(REPORTS + 'report-config', data);
}

export function removeReportConfig(groupId) {
  return axios.delete<{result: any}>(REPORTS + 'report-config?id=' + groupId);
}