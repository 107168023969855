import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLines, getRevisionsPlans, getTowersLine } from "../redux/ReportsCRUD";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { getPeriodsList } from "../../../../core/FuncionesFechas";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import axios from "axios";
import { mostrarError } from "../../../../core/EmisorMensajes";
const { REACT_APP_API_URL } = process.env

const defaultDropdownButtonStyle = {
    padding: '8px',
    backgroundColor: '#FFF',
    borderRadius: '2px',
    borderWidth: 0,
    //boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.36,
    alignItems: 'baseline',
    background: '#FFF',
    boxSizing: 'border-box',
    borderColor: '#eee',
    color: '#515151',
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    maxWidth: '100%',
    flexWrap: 'nowrap',
    outline: 'currentcolor none medium !important',
    textAlign: 'left',
    textDecoration: 'none',
    transition: 'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: 'auto',
    ':hover': {
      background: 'white'
    }
};
const initialValues = {
    zone: '', line: '', revisions: [], startDate: '', endDate: '', period: '', towers: []
}
const ReportExport: FC = () => {

    const [lines, setLines] = useState([]);
    const [linesCompleteList, setLinesCompleteList] = useState([]);
    const [structures, setStructures] = useState([]);
    const [zones, setZones] = useState([]);
    const [revisionPlans, setRevisionPlans] = useState([]);
    const [filter, setFilter] = useState(initialValues);
    const [dataIssues, setDataIssues] = useState<any[]>([]);
    const [dataTowers, setDataTowers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const _exportIssue = React.useRef(null);
    const _exportTowers = React.useRef(null);

    useEffect(() => {
        getLines().then((res: any) => {
            setLinesCompleteList(res.data);
            const zonas = res.data.map(line => line.zone);
            const opciones = Array.from(new Set(zonas.map(z => z.id)))
            .map(id => {
                return {
                    id,
                    descripcion: zonas.find(z => z.id === id).name,
                }
            });
            setZones(opciones)
        });

        getRevisionsPlans().then((res: any) => {
            res.data.forEach((r, index) => {
                r.label = `N° ${r.number} - ${r.date}`;
                r.value = index;
            });
            setRevisionPlans(res.data);
        })
    }, [])

    const cargarDatosLinea = (lineId) => {
        if(lineId) {
            const body = {
                line_id: lineId
            }
    
            getTowersLine(body)
            .then((res: any) => {
                res.data.forEach(t => {
                    t.value = t.id;
                    t.label = t.description;
                });
                setStructures(res.data);
            })
            .catch(err => {
                console.log(err);
            });

            getRevisionsPlans(lineId).then((res: any) => {
                res.data.forEach((r, index) => {
                    r.label = `N° ${r.number} - ${r.date}`;
                    r.value = index;
                });
                setRevisionPlans(res.data);
            })
        }
    }


    const formik = useFormik<any>({
        initialValues: { name: '' },
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus();
            setSubmitting(false);    
        }
    });

    const search = (exportExcel, tipoExport, event) => {
        event.stopPropagation();
        event.preventDefault();

        const filterValues = {
            ...filter,
            ...formik.values
        };
        let revisiones = [];
        if(filterValues.revisions && Array.isArray(filterValues.revisions)) {
            revisiones = filterValues.revisions.filter(r => r.date && r.number);
        }

        let estructuras = [];
        if(filterValues.towers && Array.isArray(filterValues.towers)) {
            estructuras = filterValues.towers.filter(r => r.id).map(r => r.id);
        }

        const filterData = {
            startDate: filterValues.startDate,
            endDate: filterValues.endDate,
            period: filterValues.period ? filterValues.period : null,
            line_id: filterValues.line ? filterValues.line : null,
            revisions: revisiones,
            zone_id: filterValues.zone,
            towers: estructuras
        }

        setLoading(true);
        const promises = [
            axios.post(`${REACT_APP_API_URL}/report/export-issue-data`, filterData),
            axios.post(`${REACT_APP_API_URL}/report/export-tower-data`, filterData)
        ];
        axios.all(promises)
        .then(([issuesRes, towersRes]) => {
            //todo o solo incidencias
            const exportIncidencias = (tipoExport === 0 || tipoExport === 2); 
            if(exportIncidencias && issuesRes.data){
                setDataIssues(issuesRes.data);     
            }

            //todo o solo estructuras
            const exportEstructuras = (tipoExport === 0 || tipoExport === 1); 
            if(exportEstructuras && towersRes.data){
                setDataTowers(towersRes.data);     
            }
            if(exportExcel) {
                handleExportExcel(exportEstructuras, exportIncidencias);
            }

            setLoading(false);
        })
        .catch(err => {
            mostrarError(err);
        });
    }

    const handleExportExcel = (estructuras, incidencias) => {
        
        if (incidencias && _exportIssue.current !== null) {
            _exportIssue.current.save();
        }
        if (estructuras && _exportTowers.current !== null) {
            _exportTowers.current.save();
        }
    }

    const onChangeMultiselect = (value, event) => {
        let towersSelection = [];
        if (event.action === "select-option" && event.option.value === "*") {
            towersSelection = [{ label: t('all'), value: "*" }, ...structures];
        } 
        else if (event.action === "deselect-option" && event.option.value === "*") {
            towersSelection = [];
        } 
        else if (value.length === structures.length) {
            towersSelection = [{ label: t('all'), value: "*" }, ...structures];
        } 
        else {
            towersSelection = value;
        }

        setFilter({
            ...filter,
            towers: towersSelection
        });
    }

    const customStyles = {
        dropdownButton: () => ({
            ...defaultDropdownButtonStyle,
            border: 'solid 1px #eee',
            borderRadius: '5px',
            width: '100%',
        }),
    }

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={formik.handleSubmit} className="form">
                    <h3 className="mb-5">Filtros para zonas y estructuras</h3>
                    <div className="form-group row">
                        <div className={'col-lg-4'}>
                            <div className="form-group">
                                <label htmlFor={'zone'}>
                                    {t('zone')}
                                </label>
                                <select
                                    className="form-select"
                                    name='zone'
                                    value={formik.values.zone}
                                    onChange={(e) => {
                                        const lines = linesCompleteList.filter(line => line.zone_id === e.target.value);
                                        setLines(lines);
                                        formik.handleChange(e);
                                    }}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        zones.map(zone => (
                                            <option key={zone.id} value={zone.id}>{zone.descripcion}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={'col-lg-4'}>
                            <div className="form-group">
                                <label htmlFor={'line'}>
                                    {t('line')}
                                </label>
                                <select
                                    className="form-select"
                                    name='line'
                                    value={formik.values.line}
                                    onChange={(e) => {
                                        cargarDatosLinea(e.target.value);
                                        formik.handleChange(e);
                                    }}
                                >
                                    <option>{t('selectOneOption')}</option>
                                    {
                                        lines.map(line => (
                                            <option key={line.id} value={line.id}>{line.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4' key='structure'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('structure')}</label>
                                <ReactMultiSelectCheckboxes
                                    className='form-control multiselect-checkbox'
                                    name="towers"
                                    styles={customStyles}
                                    isClearable={true}
                                    value={filter.towers}
                                    multiple={true}
                                    width={'100%'}
                                    placeholder={t("enterAValue...")}
                                    placeholderButtonLabel={t('selectOneOption')}
                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                    }}
                                    onChange={(value, event) => onChangeMultiselect(value, event)}
                                    options={[{ label: t('all'), value: "*" }, ...structures]}
                                >
                                </ReactMultiSelectCheckboxes>
                            </div>
                        </div>
                    </div>
                    <h3 className="mt-10 mb-5">Filtros para revisiones e incidencias</h3>
                    <div className="form-group row">
                        <div className='col-lg-3'>
                            <div className='form-group'>
                                <label htmlFor='start-date'>{t('startDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="startDate"
                                    value={filter.startDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revisions: [],
                                        period: '',
                                        startDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-lg-3' key='end-date'>
                            <div className='form-group'>
                                <label htmlFor='end-date'>{t('endDate')}</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    placeholder='dd/mm/aaaa'
                                    name="endDate"
                                    value={filter.endDate}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        revisions: [],
                                        period: '',
                                        endDate: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-lg-3' key='period'>
                            <div className='form-group'>
                                <label htmlFor='end-date'>{t('period')}</label>
                                <select
                                    className='form-select'
                                    name="period"
                                    value={filter.period}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        startDate: '',
                                        endDate: '',
                                        revisions: [],
                                        period: e.target.value
                                    })}
                                >
                                    <option value="">{t('selectOneOption')}</option>
                                    {
                                        getPeriodsList(t).map(period => (
                                            <option value={period.id} key={period.id}>{period.descripcion}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3' key='revision'>
                            <div className='form-group'>
                                <label htmlFor='line'>{t('revision')}</label>
                                <ReactMultiSelectCheckboxes
                                    className='form-control multiselect-checkbox'
                                    name="revision"
                                    styles={customStyles}
                                    isClearable={true}
                                    value={filter.revisions}
                                    multiple={true}
                                    width={'100%'}
                                    placeholder={t("enterAValue...")}
                                    placeholderButtonLabel={t('selectOneOption')}
                                    getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                        return value && value.length > 0 ? `${value.length} ítems seleccionados` : placeholderButtonLabel;
                                    }}
                                    onChange={(value) => setFilter({
                                        ...filter,
                                        startDate: '', 
                                        endDate: '',
                                        revisions: value
                                    })}
                                    options={revisionPlans}
                                >
                                </ReactMultiSelectCheckboxes>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-10">
                        <button
                            type="button" disabled={loading}
                            className="btn btn-success btn-sm m-1"
                            color="success"
                            title={t('exportExcelStructures')}
                            onClick={(e) => search(true, 1, e)}>
                            <i className="fa fa-file-excel" /> {t('exportExcelStructures')}
                        </button>
                        <button
                            type="button" disabled={loading}
                            className="btn btn-success btn-sm m-1"
                            color="success"
                            title={t('exportExcelIncidents')}
                            onClick={(e) => search(true, 2, e)}>
                            <i className="fa fa-file-excel" /> {t('exportExcelIncidents')}
                        </button>
                        <button
                            type="button" disabled={loading}
                            className="btn btn-success btn-sm m-1"
                            color="success"
                            title={t('exportExcelAll')}
                            onClick={(e) => search(true, 0, e)}>
                            <i className="fa fa-file-excel" /> {t('exportExcelAll')}
                        </button>
                    </div>
                </form>
                <React.Fragment>
                    <ExcelExport
                        data={dataIssues}
                        fileName="Detalle_Incidencias.xlsx"
                        ref={_exportIssue}>
                        <ExcelExportColumn field={"line_name"} title={"Línea"}/>
                        <ExcelExportColumn field={"tower_name"} title={"Estructura"} />
                        <ExcelExportColumn field={"issue_code_name"} title={"Cód. incidencia"} />
                        <ExcelExportColumn field={"issue_code_description"} title={"Descripción"} />
                        <ExcelExportColumn field={"rev_number"} title={"Número revisión"} />
                        <ExcelExportColumn field={"rev_date"} title={"Fecha revisión"} />
                        <ExcelExportColumn field={"issue_number"} title={"Número incidencia"} />
                        <ExcelExportColumn field={"issue_status"} title={"Estado"} />
                        <ExcelExportColumn field={"ot_number"} title={"Número OT"} />
                        <ExcelExportColumn field={"schedule_date"} title={"Fecha inicio OT"} />
                        <ExcelExportColumn field={"end_date"} title={"Fecha fin OT"} />
                    </ExcelExport>
                    <ExcelExport
                        data={dataTowers}
                        fileName="Detalle_Estructuras.xlsx"
                        ref={_exportTowers}>
                        <ExcelExportColumn field={"zone_name"} title={"Zona"}/>
                        <ExcelExportColumn field={"line_name"} title={"Línea nombre"}/>
                        <ExcelExportColumn field={"line_code"} title={"Línea código"} />
                        <ExcelExportColumn field={"start"} title={"Inicio"} />
                        <ExcelExportColumn field={"end"} title={"Fin"} />
                        <ExcelExportColumn field={"voltage_level"} title={"Nivel de tensión"} />
                        <ExcelExportColumn field={"tower_count"} title={"Cantidad torres"} />
                        <ExcelExportColumn field={"length"} title={"Longitud"} />
                        <ExcelExportColumn field={"geometry"} title={"Geometría"} />
                        <ExcelExportColumn field={"tower_name"} title={"Estructura"} />
                        <ExcelExportColumn field={"latitude"} title={"Latitud"} />
                        <ExcelExportColumn field={"longitude"} title={"Longitud"} />
                        <ExcelExportColumn field={"tower_line_status"} title={"Estado"} />
                        <ExcelExportColumn field={"type"} title={"Tipo"} />
                    </ExcelExport>
                </React.Fragment>
            </div>
        </div>
    );
}

export { ReportExport };