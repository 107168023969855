import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import { useTranslation } from 'react-i18next'

export function MenuInner() {
  const { t } = useTranslation();

  return (
    <>
      <MenuItem title={t('map')} to='/map' />
      <MenuItem title={t('dashboard')} to='/dashboard' />
      <MenuItem title={t('schedule')} to='/scheduler' />
      <MenuInnerWithSub
        title={t('wo')}
        to='/wos'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* OT */}
        <MenuItem
          title={t('generateWO')}
          to='/wos/create'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('manageWO')}
          to='/wos/manage'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('reportWO')}
          to='/wos/report'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('teamsSettings')}
          to='/wos/teams'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={t('reports')}
        to='/report'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          title={t('favoriteReports')}
          to='/report/list'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('generalReport')}
          to='/report/general'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('generalReportPerLine')}
          to='/report/general-line'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('recordPerLine')}
          to='/report/historic'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('incidentsPerLine')}
          to='/report/issues'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('exportLineInformation')}
          to='/report/export'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('schedulingReports')}
          to='/report/config'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={t('uploadData')}
        to='/upload'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          title={t('structures')}
          to='/upload/structure'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('revisions')}
          to='/upload/issue'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('images')}
          to='/upload/image'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('videos')}
          to='/upload/video'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={t('TWS')}
        to='/tws'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          title={t('map')}
          to='/tws/map'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('ROCAlertGeneration')}
          to='/tws/alerts'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('dataQuery')}
          to='/tws/data'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
        <MenuItem
          title={t('ROCAlertReport')}
          to='/tws/report'
          fontIcon='fa-angle-right'
        >
        </MenuItem>
      </MenuInnerWithSub>
    </>
  )
}
