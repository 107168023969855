import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function mostrarError(
  mensaje = "Error inesperado! No se pudo completar la tarea"
) {
  toast(mensaje, {
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export function mostrarExito(mensaje = "Tarea completada con éxito") {
  toast(mensaje, {
    type: toast.TYPE.SUCCESS,
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export function mostrarWarning(mensaje = "Validación incorrecta de campos") {
  toast(mensaje, {
    type: toast.TYPE.WARNING,
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export function mostrarInfo(mensaje = "") {
  toast(mensaje, {
    type: toast.TYPE.INFO,
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export function mostrarErrorJson(json) {
  if(json.response && json.response.data) {
    toast(json.response.data, {
      type: toast.TYPE.WARNING,
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
};